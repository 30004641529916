import React, { Component } from "react";
import * as navbarStyles from "./styles/css/navbar.module.css";
import nav_logo from "../styles/images/logo_blanc_nav.png";
import nav_logo_orange from "../styles/images/logo_orange_nav.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";
const Functions = require('./styles/js/functions');

var e1;

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: false,
            sticky:false
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        e1 = getComputedStyle(document.getElementsByClassName("tl-wrapper")[0]);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }

    handleClick = () => {
        this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));
    }

    handleMouseEnter = () =>{
        /*var curs = document.getElementById("cursor");
        curs.classList.add("big_cursor")*/
    }

    handleMouseLeave = () =>{
        /*var curs = document.getElementById("cursor");
        curs.classList.remove("big_cursor")*/
    }

    handleScroll = () => {
        if (window.pageYOffset > 1) {
            if(e1.transform !== "none"){
                document.getElementsByClassName("tl-wrapper")[0].style.removeProperty('transform');
            }
            this.setState({
                sticky:true
              });
        }else{
            this.setState({
                sticky:false
              });
        }
    }

    render() {
        return(
            <header className={this.state.sticky && !this.state.isToggleOn ? navbarStyles.navbar_sticky : navbarStyles.navbar} onMouseEnter={this.state.sticky && !this.state.isToggleOn ? Functions.handleMouseEnter : null} onMouseLeave={this.state.sticky && !this.state.isToggleOn ? Functions.handleMouseLeave : null}>
                <a href="/" className={navbarStyles.navbar_btn} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <img src={this.state.sticky && !this.state.isToggleOn ? nav_logo_orange : !this.state.isToggleOn && this.props.color === "orange" ? nav_logo_orange : nav_logo} className={navbarStyles.logo} alt={"Logo WDS"}></img>
                </a>
                <button className={navbarStyles.navbar_btn} onClick={() => this.handleClick()} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <div className={this.state.isToggleOn ? navbarStyles.hamburger_open : navbarStyles.hamburger}>
                        <span style={this.state.sticky && !this.state.isToggleOn ? {background : "orange"} : !this.state.isToggleOn ? {background:this.props.color} : {background : "white"}}></span>
                        <span style={this.state.sticky && !this.state.isToggleOn ? {background : "orange"} : !this.state.isToggleOn ? {background:this.props.color} : {background : "white"}}></span>
                    </div>
                </button>
                <div id="menu" className={this.state.isToggleOn ? navbarStyles.menu_panel : navbarStyles.invisible}>
                    <ul className={navbarStyles.menu}>
                        <li>
                            <AniLink paintDrip color="purple" to="/" activeStyle={{ color: "white", backgroundPosition :"0"}} className={navbarStyles.menu_item} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Accueil</AniLink>
                        </li>
                        <li>
                            <AniLink paintDrip color="purple" to="/services/" activeStyle={{ color: "white", backgroundPosition :"0"}} className={navbarStyles.menu_item} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Services</AniLink>
                        </li>
                        <li>
                            <AniLink paintDrip color="purple" to="/projets/" activeStyle={{ color: "white", backgroundPosition :"0"}} className={navbarStyles.menu_item} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Réalisations</AniLink>
                        </li>
                        <li>
                            <AniLink paintDrip color="purple" to="/contact/" activeStyle={{ color: "white", backgroundPosition :"0"}} className={navbarStyles.menu_item} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Contact</AniLink>
                        </li>
                    </ul>
                    {/* <ul className={navbarStyles.mentions}>
                        <li>
                            <AniLink cover direction="up" bg="#800080" to="/mentionsLegales/" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Mentions légales</AniLink>
                        </li>
                        <li>
                            <AniLink cover direction="up" bg="#800080" to="/politiqueDeConfidentialite/" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Politique de confidentialité</AniLink>
                        </li>
                    </ul>
        */}
                </div>
            </header>
        );
    }
}

export default Navbar;