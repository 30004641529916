// extracted by mini-css-extract-plugin
export const navbar = "navbar-module--navbar--31g2e";
export const navbar_sticky = "navbar-module--navbar_sticky--3jBbr";
export const logo = "navbar-module--logo--3waqK";
export const navbar_btn = "navbar-module--navbar_btn--2Im9q";
export const hamburger = "navbar-module--hamburger--tWLOO";
export const hamburger_open = "navbar-module--hamburger_open--17-8K";
export const menu_panel = "navbar-module--menu_panel--10uZM";
export const invisible = "navbar-module--invisible--27ZW3";
export const menu = "navbar-module--menu--1mZxR";
export const menu_item = "navbar-module--menu_item--2qzfa";
export const mentions = "navbar-module--mentions--3Ke7n";